@font-face {
  font-family: "Trade Gothic LT";
  src: url("../fonts/Trade_Gothic_LT_Std.otf");
}

@font-face {
  font-family: "Trade Gothic LT";
  font-weight: 700;
  src: url("../fonts/Trade_Gothic_LT_Bold.ttf");
}

@font-face {
  font-family: "Open Sans Condensed Light";
  src: url("../fonts/Open_Sans_Cond_Light.ttf");
}
